import * as React from "react";
import afterPay from "../images/payment/afterpay.svg"
import amex from "../images/payment/amex.svg"
import applePay from "../images/payment/apple-pay.svg"
import googlePay from "../images/payment/google-pay.svg"
import mastercard from "../images/payment/mastercard.svg"
import visa from "../images/payment/visa.svg"

export default function Payment() {
    return (
        <div className="flex gap-2.5 md:gap-6 items-center">
            <Visa />
            <Mastercard />
            <Amex />
            <ApplePay />
            <GooglePay />
            <AfterPay />
        </div>
    )
}

function Visa() {
    return (
        <img src={visa} className="h-[2.35rem]" alt="Pasteleria" />
    );
}

function Mastercard() {
    return (
        <img src={mastercard} className="h-[2.4rem]" alt="Pasteleria" />
    );
}

function Amex() {
    return (
        <img src={amex} className="h-[2.6rem]" alt="Pasteleria" />
    );
}

function ApplePay() {
    return (
        <img src={applePay} className="h-[2.35rem]" alt="Pasteleria" />
    );
}

function GooglePay() {
    return (
        <img src={googlePay} className="h-[2.25rem]" alt="Pasteleria" />
    );
}

function AfterPay() {
    return (
        <img src={afterPay} className="h-[2.45rem]" alt="Pasteleria" />
    );
}