import React, { useContext, useReducer, useEffect } from 'react'
import { useSessionStorage, useLocalStorage } from '../hooks/use-web-storage.js';
import { STORE_KEY, CART_ACTION } from './../constants.js';
import { v4 as uuidv4 } from 'uuid';

const CartItemsContext = React.createContext([]);
export const useCartItemsContext = () => useContext(CartItemsContext)
const CartActionContext = React.createContext({});
export const useCartActionContext = () => useContext(CartActionContext)

const cartReducer = (state, action) => {
    let newState = [...state];
    switch (action.type) {
        case CART_ACTION.ADD:
            newState = [...state, { ...action.payload.cake, key: uuidv4(), quantity: 1 }];
            break;
        case CART_ACTION.UPDATE:
            const index = state.findIndex(x => x.key === action.payload.cake.key);
            state[index] = action.payload.cake;
            newState = [...state];
            break;
        case CART_ACTION.REMOVE:
            newState = [...state.filter(x => x.key !== action.payload.cake.key)];
            break;
        case CART_ACTION.CLEAR:
            newState = [];
            break
        default:
            console.error("Invalid cart action.", action.type)
            return state;
    }
    return newState
}

export default function CartProvider({ children }) {
    const [cartInStore, setCartInStore, removeCartInStore] = useSessionStorage(STORE_KEY.CART_ITEMS, () => [])

    const [cartItems, cartDispatch] = useReducer(cartReducer, cartInStore);

    const cartAction = {
        addCake: (cake) => { cartDispatch({ type: CART_ACTION.ADD, payload: { cake: cake } }) },
        updateCake: (cake) => { cartDispatch({ type: CART_ACTION.UPDATE, payload: { cake: cake } }) },
        removeCake: (cake) => { cartDispatch({ type: CART_ACTION.REMOVE, payload: { cake: cake } }) },
        clearAll: () => { cartDispatch({ type: CART_ACTION.CLEAR }) },
    }

    useEffect(() => {
        setCartInStore(cartItems);
        return () => {
        }
    }, [cartItems])

    return (
        <CartActionContext.Provider value={cartAction}>
            <CartItemsContext.Provider value={cartItems}>
                {children}
            </CartItemsContext.Provider>
        </CartActionContext.Provider>
    )
}
