export const STORE_KEY = {
    CART_ITEMS: "pasteleria.cartItems",
    CHECKOUT: "pasteleria.checkout"
}

export const CART_ACTION = {
    ADD: 'cart-add',
    UPDATE: 'cart-update',
    REMOVE: 'cart-remove',
    CLEAR: 'cart-clear'
}

export const CHECKOUT_ACTION = {
    UPSERT_CUSTOMER_INFO: 'checkout-upsert-customer-info',
    UPSERT_NOTES: 'checkout-upsert-notes',
    UPSERT_FULFILLMENT: 'checkout-upsert-fulfillment',
    CLEAR: 'checkout-clear'
}

export const FULFILLMENT = {
    DELIVERY: 'delivery',
    PICKUP: 'pickUp'
}

export const DIET = {
    GF: 'gf',
    DF: 'df',
    V: 'v'
}

export const FLAVOURS = [
    { name: "BANOFFEE", ingredients: ["banana sponge", "dulce de leche", "banana cream"] },
    { name: "CHOCOLATE HAZELNUT", ingredients: ["chocolate sponge", "hazelnut praline", "dark chocolate cream"] },
    { name: "CHOCOLATE RASPBERRY", ingredients: ["chocolate sponge", "raspberry jam", "dark chocolate cream"] },
    { name: "DARK CHOCOLATE", ingredients: ["chocolate sponge", "dark chocolate cream"] },
    { name: "LEMON", ingredients: ["lemon sponge", "lemon curd", "lemon cream"] },
    { name: "MATCHA", ingredients: ["matcha sponge", "matcha cream"] },
    { name: "STRAWBERRY VANILLA", ingredients: ["vanilla sponge", "strawberry jam", "white chocolate cream"] },
    { name: "TROPICAL", ingredients: ["vanilla sponge", "passionfruit jam", "mango cream"] },
    { name: "VANILLA WHITE CHOCOLATE", ingredients: ["vanilla sponge", "vanilla white chocolate cream"] },
    { name: "OREO", ingredients: ["chocolate sponge", "oreo crumble", "oreo cream"] }
]