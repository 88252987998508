import * as React from "react"
import { Link, navigate } from "gatsby"
import Payment from "./payment";

function Footer() {
	return (
		<footer className="py-2 pt-[1.65rem] md:pt-[4.5rem] w-full text-center">
			<div className="flex justify-center my-3 mb-1 md:mb-2">
				<Payment />
			</div>
			<div className="flex flex-row 
			font-ovo justify-between text-xs tracking-wider text-pas-gray max-w-lg mx-auto mb-1 md:mb-2">
				{/* <Link to='/contact'>Contact Us</Link> */}
				<Link to='/refund-policy'>Refund Policy</Link>
				<Link to='/terms-and-conditions'>Terms & Conditions</Link>
				<Link to='/privacy-policy'>Privacy Policy</Link>
			</div>
			<div className="font-ovo text-xs tracking-wider text-pas-gray mb-1">© {new Date().getFullYear()} Pasteleria. All Rights Reserved.</div>
		</footer>
	);
}

export default Footer;

