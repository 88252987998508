import React, { useContext, useReducer, useEffect } from 'react'
import { CHECKOUT_ACTION, STORE_KEY } from '../constants.js';
import { useSessionStorage } from '../hooks/use-web-storage.js';
const CheckoutContext = React.createContext({ customer: {}, fulfillment: {}, orderNotes: {} });
export const useCheckoutContext = () => useContext(CheckoutContext)
const CheckoutActionContext = React.createContext( {
    upsertCustomerInfo: x => x,
    upsertFulfillment: x => x,
    upsertOrderNotes: x => x,
    clearAll: () => {},
});
export const useCheckoutActionContext = () => useContext(CheckoutActionContext)

const checkoutReducer = (state, action) => {
    let newState = { ...state };
    switch (action.type) {
        case CHECKOUT_ACTION.UPSERT_CUSTOMER_INFO:
            newState = { ...state, customer: action.payload.customer };
            break;
        case CHECKOUT_ACTION.UPSERT_FULFILLMENT:
            newState = { ...state, fulfillment: action.payload.fulfillment };
            break;
        case CHECKOUT_ACTION.UPSERT_NOTES:
            newState = { ...state, orderNotes: action.payload.orderNotes };
            break;
        case CHECKOUT_ACTION.CLEAR:
            newState = { customer: {}, fulfillment: {}, orderNotes: {} };
            break
        default:
            console.error("Invalid checkout action.", action.type)
            return state;
    }
    return newState
}

export default function CheckoutProvider({ children }) {
    const [checkoutInSession, setCheckoutInSession, removeCartInStore] = useSessionStorage(STORE_KEY.CHECKOUT, { customer: {}, fulfillment: {}, orderNotes: {} })
    const [checkout, checkoutDispatch] = useReducer(checkoutReducer, checkoutInSession);

    const checkoutAction = {
        upsertCustomerInfo: (customer) => { checkoutDispatch({ type: CHECKOUT_ACTION.UPSERT_CUSTOMER_INFO, payload: { customer } }) },
        upsertFulfillment: (fulfillment) => { checkoutDispatch({ type: CHECKOUT_ACTION.UPSERT_FULFILLMENT, payload: { fulfillment } }) },
        upsertOrderNotes: (orderNotes) => { checkoutDispatch({ type: CHECKOUT_ACTION.UPSERT_NOTES, payload: { orderNotes } }) },
        clearAll: () => { checkoutDispatch({ type: CHECKOUT_ACTION.CLEAR }) },
    }

    useEffect(() => {
        setCheckoutInSession(checkout);
        return () => {
            
        }
    }, [checkout])

    return (
        <CheckoutActionContext.Provider value={checkoutAction}>
            <CheckoutContext.Provider value={checkout}>
                {children}
            </CheckoutContext.Provider>
        </CheckoutActionContext.Provider>
    )
}
